import {Outlet, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";


export const ComplianceLayout = () => {

    const path = useLocation().pathname;

    const pathElements = path.split('/').filter((el) => el !== '');

    return <div className={'w-100'}>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active={path === '/compliance/risk-assessment'} href={'/compliance/risk-assessment'}>Risk Assessments</Breadcrumb.Item>
            {pathElements.length > 2 && <Breadcrumb.Item active={true}>{decodeURIComponent(pathElements[2])}</Breadcrumb.Item>}
        </Breadcrumb>
        <Outlet></Outlet>
    </div>
}