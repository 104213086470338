import {Button, Col, Container, Modal, Row, Table, Form} from "react-bootstrap";
import {DBRiskAssessment} from "../model/databaseSchema";
import {useEffect, useState} from "react";
import {LoadableValue} from "../model/LoadableValue";
import {callAPI} from "../model/API";
import {
    CreateNewRiskAssessmentRequest, CreateNewRiskAssessmentResponse,
    GetRiskAssessmentsRequest, GetRiskAssessmentsResponse
} from "../model/RequestTypes";
import {Link, useNavigate} from "react-router-dom";

type RiskAssessmentsPageComponentProps = {
    riskAssessments: DBRiskAssessment[]
    createNewRiskAssessment: (name: string) => Promise<void>
}


const RiskAssessmentsPageComponent = ({
                                          riskAssessments,
                                          createNewRiskAssessment
                                      }: RiskAssessmentsPageComponentProps) => {

    const [showNewRiskAssessmentModal, setShowNewRiskAssessmentModal] = useState<boolean>(false);
    const [newRiskAssessmentName, setNewRiskAssessmentName] = useState<string>('');


    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className={'ds-panel mt-3'}>
                        <h1 className={'ds-panel-header'}>Risk Assessments</h1>

                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Summary</th>
                                <th>Created</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {riskAssessments.map((riskAssessment) => {
                                return (
                                    <tr key={riskAssessment.name}>
                                        <td>{riskAssessment.name}</td>
                                        <td>{riskAssessment.riskSummary === '' ? "No Summary" : riskAssessment.riskSummary}</td>
                                        <td>{riskAssessment.createdAt ? new Date(riskAssessment.createdAt).toLocaleString("en-GB") : "Unknown"}</td>
                                        <th><Link to={`./${encodeURIComponent(riskAssessment.name)}`}><Button>Open</Button> </Link></th>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        <Button onClick={() => setShowNewRiskAssessmentModal(true)}>New Risk Assessment</Button>
                        <Modal show={showNewRiskAssessmentModal} onHide={() => setShowNewRiskAssessmentModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>New Risk Assessment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type={'text'} onChange={(e) => setNewRiskAssessmentName(e.target.value)} value={newRiskAssessmentName}/>
                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowNewRiskAssessmentModal(false)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" disabled={newRiskAssessmentName === ''} onClick={() => createNewRiskAssessment(newRiskAssessmentName)}>
                                    Create
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

type RiskAssessmentsPageState = {
    riskAssessments: DBRiskAssessment[]
}


export const RiskAssessmentsPage = () => {

    const [pageState, setPageState] = useState<LoadableValue<RiskAssessmentsPageState>>({type: 'loading'});
    const navigate = useNavigate();


    useEffect(() => {
        const fetchRiskAssessments = async () => {
            try {
                const riskAssessments = await callAPI<GetRiskAssessmentsRequest, GetRiskAssessmentsResponse>({type: 'getRiskAssessments'});
                setPageState({type: 'loaded', riskAssessments: riskAssessments.riskAssessments});
            } catch (e: any) {
                setPageState({type: 'error', error: e});
            }
        }
        fetchRiskAssessments();
    }, []);

    const createNewRiskAssessment = async (name: string) => {
        await callAPI<CreateNewRiskAssessmentRequest, CreateNewRiskAssessmentResponse>({type: 'createNewRiskAssessment', name});
        navigate(`./${encodeURIComponent(name)}`);
    }


    if (pageState.type === 'loading') {
        return <div>Loading...</div>
    }

    if (pageState.type === 'error') {
        return <div>Error: {pageState.error.message}</div>
    }

    return <RiskAssessmentsPageComponent riskAssessments={pageState.riskAssessments}
                                         createNewRiskAssessment={createNewRiskAssessment}/>
}