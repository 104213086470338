import React, {useEffect} from 'react';
import './App.css';
import Layout from './layout';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {GuidesLayout} from "./guides/layout";
import {init} from "./model/firebaseConnection";
import Page from "./page";
import {GuidesPage} from "./guides/guidesPage";
import {GuidePage} from "./guides/guidePage";
import {SettingsLayout} from "./settings/layout";
import {SettingsPage} from "./settings/page";
import {OnboardingPage} from "./onboarding/page";
import {DemoPage} from "./demo/page";
import {AuthenticationAction} from "./components/authentication/AuthenticationAction";
import {PaymentReturn} from "./components/layout/PaymentReturn";
import {AddTaskPage} from "./addTask/page";
import {ComplianceLayout} from "./compliance/layout";
import {RiskAssessment} from "./compliance/riskAssessment";
import {RiskAssessmentsPage} from "./compliance/RiskAssessmentsPage";
import {DemoRiskAssessmentPage} from "./demo/DemoRiskAssessmentPage";

init();


const ErrorPage = () => {
    return (
        <div className={'_404'}>
            <h3>Page Not Found</h3>
            <a href={'/'}>Return to Home</a>
        </div>
    )
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout></Layout>,
        children: [
            {
                path: "/",
                element: <Page></Page>
            },
            {
                path: "/_auth",
                element: <AuthenticationAction></AuthenticationAction>
            },
            {
                path: "/_paymentReturn",
                element: <PaymentReturn></PaymentReturn>
            },
            {
                path: "/guides",
                element: <GuidesLayout></GuidesLayout>,
                children: [
                    {
                        path: "/guides",
                        element: <GuidesPage></GuidesPage>
                    },
                    {
                        path: "/guides/:guideId",
                        element: <GuidePage></GuidePage>
                    }
                ]
            },
            {
                path: "/compliance",
                element: <ComplianceLayout></ComplianceLayout>,
                children: [
                    {
                        path: "/compliance/risk-assessment",
                        element: <RiskAssessmentsPage></RiskAssessmentsPage>
                    },
                    {
                        path: "/compliance/risk-assessment/:riskAssessmentName",
                        element: <RiskAssessment></RiskAssessment>
                    }
                ]
            },
            {
                path: "/settings",
                element: <SettingsLayout></SettingsLayout>,
                children: [
                    {
                        path: "/settings",
                        element: <SettingsPage></SettingsPage>
                    }
                ]
            },
            {
                path: "/onboarding",
                element: <OnboardingPage></OnboardingPage>
            },
            {
                path: "/dental",
                element: <OnboardingPage></OnboardingPage>
            },
            {
                path: "/demo",
                element: <DemoPage></DemoPage>
            },
            {
                path: "/demo/risk-assessment",
                element: <DemoRiskAssessmentPage></DemoRiskAssessmentPage>
            },
            {
                path: "/addTask",
                element: <AddTaskPage></AddTaskPage>
            },
            {
                path: "/404",
                element: <ErrorPage></ErrorPage>
            }
        ]
    }
]);


function App() {
    useEffect(() => {
        if (window?.Cypress) {
            return;
        }
        const script = document.createElement('script');

        const heapProject = process.env.REACT_APP_HEAP_PROJECT_ID;

        if (!heapProject || heapProject === "none") {
            return;
        }

        script.innerText = `
            window.heapReadyCb = window.heapReadyCb || [], window.heap = window.heap || [], heap.load = function (e, t){window.heap.envId = e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
            heap.load("${heapProject}");
        `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
