import {DBRiskAssessment} from "../../../functions/src/databaseSchema";
import {useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {ProgressIndicator} from "../common/ProgressIndicator";
import {RiskAssessmentEditor} from "./RiskAssessmentEditor";

export type RiskAssessmentComponentProps = {
    riskAssessment: DBRiskAssessment
    regenerateRiskAssessment: (input: string) => Promise<void>
    editRiskAssessment: (riskAssessment: DBRiskAssessment) => Promise<void>
}


export const RiskAssessmentComponent = ({riskAssessment, regenerateRiskAssessment, editRiskAssessment}: RiskAssessmentComponentProps) => {

    const [riskAssessmentInput, setRiskAssessmentInput] = useState<string>('');
    const [generating, setGenerating] = useState<boolean>(false);

    const wrapRegenerateRiskAssessment = async () => {
        setGenerating(true);
        await regenerateRiskAssessment(riskAssessmentInput);
        setGenerating(false);
    }


    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <div className={'ds-panel mt-3'}>
                        <h2 className={'ds-panel-header'}>Risk Assessment for {riskAssessment.name}</h2>
                        <Form.Group className={'mt-2'}>
                            <Form.Label>Risk Assessment Name</Form.Label>
                            <Form.Control type={'text'} value={riskAssessment.name} onChange={(e) => editRiskAssessment({...riskAssessment, name: e.target.value})}/>
                        </Form.Group>
                        <Form.Group className={'mt-2'}>
                            <Form.Label>Instructions for the AI</Form.Label>
                            <Form.Control type={'text'} as={'textarea'} rows={10} value={riskAssessmentInput}
                                          onChange={(e) => setRiskAssessmentInput(e.target.value)}/>
                        </Form.Group>
                        <Button className={'mt-3'} onClick={wrapRegenerateRiskAssessment}
                                disabled={!!generating}>Re-Generate With AI</Button>
                    </div>
                    <div className={'ds-panel mt-3'}>
                    <h2 className={'ds-panel-header'}>Assessment</h2>
                        {generating ?
                            <ProgressIndicator text={"Generating New Risk Assessment With AI"} maxSeconds={300}/> :
                            <RiskAssessmentEditor riskAssessment={riskAssessment} editRiskAssessment={editRiskAssessment}/>}
                    </div>
                </Col>
            </Row>
        </Container>

    )


}