import React, {useState} from "react";
import {OnboardingSubject, Task} from "../model/RequestTypes";
import {TemplateTasksList} from "./TemplateTaskList";
import './OnboardingSubjectComponent.css';
import {Accordion, Button} from "react-bootstrap";
import {ProgressIndicator} from "../components/common/ProgressIndicator";
import {TaskTemplate} from "../model/databaseSchema";

export type OnboardingSubjectComponentProps = {
    subject: OnboardingSubject,
    addTasks: (tasks: Task[]) => Promise<void>,
    next: () => void
}

export const OnboardingSubjectComponent = ({subject, next, addTasks}: OnboardingSubjectComponentProps) => {

    const [selectedTasks, setSelectedTasks] = useState<Record<string, TaskTemplate>>({
        ...subject.fixedTasks.reduce((acc, task) => {
            acc[task.id] = task;
            return acc;
        }, {} as Record<string, Task>),
        ...subject.userInputTasks.reduce((acc, task) => {
            acc[task.id] = task;
            return acc;
        }, {} as Record<string, TaskTemplate>)
    });
    const [edits, setEdits] = useState<Record<string, TaskTemplate>>({});

    const [saving, setSaving] = useState<boolean>(false);

    const setSelectedTask = (task: TaskTemplate, selected: boolean) => {
        if (selected) {
            setSelectedTasks({...selectedTasks, [task.id]: task});
        } else {
            const newSelectedTasks = {...selectedTasks};
            delete newSelectedTasks[task.id];
            setSelectedTasks(newSelectedTasks);
        }
    }

    const fixedTasksOverlayedWithEdits = subject.fixedTasks.map(task => {
        if (edits[task.id]) {
            return edits[task.id];
        }
        return task;
    });

    const userInputTasksOverlayedWithEdits = subject.userInputTasks.map(task => {
        if (edits[task.id]) {
            return edits[task.id];
        }
        return task;
    });

    const editTask = (task: Task) => {
        setEdits({...edits, [task.id]: task});
    };

    const saveAndContinue = async () => {
        setSaving(true);

        const fixedTasks = fixedTasksOverlayedWithEdits.filter(task => !!selectedTasks[task.id]);
        const userInputTasks = userInputTasksOverlayedWithEdits.filter(task => !!selectedTasks[task.id]);
        await addTasks([...fixedTasks, ...userInputTasks]);
        if(next) {
            next();
        }
        setSaving(false);
    }


    return (
        <div className={'ds-panel'}>
            <h2 className={'ds-panel-header'}>{subject.name}</h2>
            {saving ?
                <ProgressIndicator text={'Saving tasks'} maxSeconds={60}/>
                :
            <div>
                {subject.userInputTasks.length === 0 && subject.fixedTasks.length === 0 &&
                    <div>
                        <h3 className={'onboarding-subject-task-group-title'}>No tasks to add</h3>
                        <p className={'onboarding-subject-task-group-explainer'}>All tasks added during the last onboarding</p>
                    </div>
                }
                {userInputTasksOverlayedWithEdits.length > 0 &&
                    <div className={'onboarding-subject-task-group'}>
                        <h3 className={'onboarding-subject-task-group-title'}>Practice Specific {subject.name} Tasks</h3>
                        <h4 className={'onboarding-subject-task-group-explainer'}>Please enter the relevant dates for your practice</h4>
                        <TemplateTasksList taskTemplates={userInputTasksOverlayedWithEdits}
                                           selectedTasks={selectedTasks} selectTask={setSelectedTask}
                                           editTask={editTask}/>
                    </div>}
                {fixedTasksOverlayedWithEdits.length > 0 &&
                    <div className={'onboarding-subject-task-group'}>
                        <h3 className={'onboarding-subject-task-group-title'}>Standard {subject.name} Tasks</h3>
                        <h4 className={'onboarding-subject-task-group-explainer'}>These generally happen at the same time for everyone, but feel free to edit the dates</h4>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{fixedTasksOverlayedWithEdits.length} Standard {subject.name} Tasks (Click to expand)</Accordion.Header>
                                <Accordion.Body>
                                    <TemplateTasksList taskTemplates={fixedTasksOverlayedWithEdits}
                                                       selectedTasks={selectedTasks}
                                                       selectTask={setSelectedTask} editTask={editTask}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                }
                <Button onClick={saveAndContinue}>Save and Continue</Button>
            </div>
            }
        </div>
    )
}