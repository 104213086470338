import {DBRiskAssessment, DBTask} from "../../model/databaseSchema";
import {Button, Form, Table} from "react-bootstrap";
import {useState} from "react";
import "./RiskAssessmentEditor.css";


export type RiskAssessmentEditorProps = {
    riskAssessment: DBRiskAssessment,
    editRiskAssessment: (riskAssessment: DBRiskAssessment) => Promise<void>
}


export const RiskAssessmentEditor = ({riskAssessment, editRiskAssessment}: RiskAssessmentEditorProps) => {

    const [editedRiskAssessment, setEditedRiskAssessment] = useState<DBRiskAssessment>(riskAssessment);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);


    const commitChanges = async () => {
        await editRiskAssessment(editedRiskAssessment);
        setUnsavedChanges(false);
    }


    return (
        <div>
            <div className={'w-100 d-flex flex-row justify-content-between mb-2'}>
                <h3>Summary</h3>
                <Button disabled={!unsavedChanges} onClick={commitChanges}>Save Changes {unsavedChanges && "*"}</Button>
            </div>
            <Form.Control as={'textarea'} rows={4} value={editedRiskAssessment.riskSummary} onChange={(e) => {
                setEditedRiskAssessment({...editedRiskAssessment, riskSummary: e.target.value});
                setUnsavedChanges(true);
            }}/>

            <h3>Hazards</h3>
            <Table bordered responsive variant={'light'}>
                <thead>
                <tr className={'risk-assessment-hazard-row'}>
                    <th>#</th>
                    <th>Hazard</th>
                    <th>Who is at risk?</th>
                    <th>Risk Level</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(editedRiskAssessment.hazards).map(([key, value]) => {
                    return (
                        <tr key={key} className={'risk-assessment-hazard-row'}>
                            <td>
                                <i className={'bi bi-trash risk-editor-action-button'} onClick={() => {
                                    const newHazards = {...editedRiskAssessment.hazards};
                                    delete newHazards[key];
                                    setEditedRiskAssessment({...editedRiskAssessment, hazards: newHazards});
                                    setUnsavedChanges(true);
                                }}/>
                            </td>
                            <td><Form.Control as={'textarea'} rows={8} value={value.hazard} style={{width: "200px"}}
                                              onChange={(e) => {
                                                  setEditedRiskAssessment({...editedRiskAssessment,
                                                      hazards: {
                                                          ...editedRiskAssessment.hazards,
                                                          [key]: {...value, hazard: e.target.value}
                                                      }
                                                  });
                                                  setUnsavedChanges(true);
                                              }}></Form.Control></td>
                            <td><Form.Control as={'textarea'} rows={8} value={value.whoMayBeHarmed}
                                              style={{width: "250px"}} onChange={
                                (e) => {
                                    setEditedRiskAssessment({
                                        ...editedRiskAssessment,
                                        hazards: {
                                            ...editedRiskAssessment.hazards,
                                            [key]: {...value, whoMayBeHarmed: e.target.value}
                                        }
                                    });
                                    setUnsavedChanges(true);
                                }
                            }></Form.Control></td>
                            <td><Form.Select style={{width: "120px"}} value={value.riskLevel} onChange={(e) => {
                                setEditedRiskAssessment({
                                    ...editedRiskAssessment,
                                    hazards: {
                                        ...editedRiskAssessment.hazards,
                                        [key]: {...value, riskLevel: e.target.value as 'Low' | 'Medium' | 'High'}
                                    }
                                });
                                setUnsavedChanges(true);
                            }}>
                                <option value={'Low'}>Low</option>
                                <option value={'Medium'}>Medium</option>
                                <option value={'High'}>High</option>
                            </Form.Select>
                            </td>
                            <td>
                                <Table striped bordered hover variant={'light'}>
                                    <thead>
                                    </thead>
                                    <tbody>
                                    {Object.entries(value.mitigatingActions).map(([actionId, action]) => {
                                        return (
                                            <tr key={actionId}>
                                                <td>
                                                    <i className={'bi bi-trash risk-editor-action-button'} onClick={() => {
                                                        const newActions = {...value.mitigatingActions};
                                                        delete newActions[actionId];
                                                        setEditedRiskAssessment({
                                                            ...editedRiskAssessment,
                                                            hazards: {
                                                                ...editedRiskAssessment.hazards,
                                                                [key]: {
                                                                    ...value,
                                                                    mitigatingActions: newActions
                                                                }
                                                            }
                                                        });
                                                        setUnsavedChanges(true);
                                                    }}/>
                                                </td>
                                                <td><Form.Control style={{width: "200px"}} as={'textarea'} rows={4} value={action.action}
                                                                  onChange={(e) => {
                                                                      setEditedRiskAssessment({
                                                                          ...editedRiskAssessment,
                                                                          hazards: {
                                                                              ...editedRiskAssessment.hazards,
                                                                              [key]: {
                                                                                  ...value,
                                                                                  mitigatingActions: {
                                                                                      ...value.mitigatingActions,
                                                                                      [actionId]: {...action, action: e.target.value}
                                                                                  }
                                                                              }
                                                                          }
                                                                      });
                                                                      setUnsavedChanges(true);
                                                                  }}></Form.Control></td>
                                                <td><Form.Control style={{width: "200px"}} value={action.responsible}
                                                                    onChange={(e) => {
                                                                        setEditedRiskAssessment({
                                                                            ...editedRiskAssessment,
                                                                            hazards: {
                                                                                ...editedRiskAssessment.hazards,
                                                                                [key]: {
                                                                                    ...value,
                                                                                    mitigatingActions: {
                                                                                        ...value.mitigatingActions,
                                                                                        [actionId]: {...action, responsible: e.target.value}
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                        setUnsavedChanges(true);
                                                                    }}></Form.Control></td>
                                                <td>
                                                    <ul>
                                                        {action.tasks.map((task, index) => {
                                                            const dbTask = task as DBTask;
                                                            let addTaskUrl = `/addTask?name=${encodeURIComponent(dbTask.name)}`;
                                                            if (dbTask.details) {
                                                                addTaskUrl += `&details=${encodeURIComponent(dbTask.details)}`;
                                                            }
                                                            if (dbTask.type === 'oneOff') {
                                                                addTaskUrl += `&type=oneOff`;
                                                                addTaskUrl += `&dueDate=${encodeURIComponent(dbTask.dueDate)}`;
                                                            }
                                                            if (dbTask.type === 'recurring') {
                                                                addTaskUrl += `&type=recurring`;
                                                                addTaskUrl += `&frequency=${encodeURIComponent(dbTask.frequency.type)}`;

                                                                if (dbTask.frequency.type === 'weekly') {
                                                                    addTaskUrl += `&dayOfWeek=${encodeURIComponent(dbTask.frequency.dayOfWeek)}`;
                                                                }
                                                                if (dbTask.frequency.type === 'monthly') {
                                                                    addTaskUrl += `&day=${encodeURIComponent(dbTask.frequency.day)}`;
                                                                }
                                                                if (dbTask.frequency.type === 'quarterly') {
                                                                    addTaskUrl += `&day=${encodeURIComponent(dbTask.frequency.day)}`;
                                                                    addTaskUrl += `&month=${encodeURIComponent(dbTask.frequency.monthNumber)}`;
                                                                }
                                                                if (dbTask.frequency.type === 'yearly') {
                                                                    addTaskUrl += `&day=${encodeURIComponent(dbTask.frequency.day)}`;
                                                                    addTaskUrl += `&month=${encodeURIComponent(dbTask.frequency.month)}`;
                                                                }
                                                            }


                                                            return <li key={index}><a
                                                                href={addTaskUrl}>{dbTask.name}</a></li>
                                                        })}
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td><i className={'bi bi-plus-square risk-editor-action-button'} onClick={() => {
                                            const newActions = {...value.mitigatingActions};
                                            const newActionId = `${Date.now()}`;
                                            newActions[newActionId] = {action: '', responsible: '', tasks: [], id: newActionId};
                                            setEditedRiskAssessment({
                                                ...editedRiskAssessment,
                                                hazards: {
                                                    ...editedRiskAssessment.hazards,
                                                    [key]: {
                                                        ...value,
                                                        mitigatingActions: newActions
                                                    }
                                                }
                                            });
                                            setUnsavedChanges(true);
                                        }

                                        }/></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                )
                })}
                <tr>
                    <td><i className={'bi bi-plus-square risk-editor-action-button'} onClick={() => {
                        const newHazards = {...editedRiskAssessment.hazards};
                        const newHazardId = `${Date.now()}`;
                        newHazards[newHazardId] = {
                            id: newHazardId,
                            hazard: '',
                            whoMayBeHarmed: '',
                            riskLevel: 'Low',
                            mitigatingActions: {}
                        };
                        setEditedRiskAssessment({...editedRiskAssessment, hazards: newHazards});
                        setUnsavedChanges(true);
                    }}/></td>
                </tr>
                </tbody>
                </Table>
                </div>
                )
                }