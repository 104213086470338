import React, {useEffect, useState} from "react";
import './page.css';
import {
    AddTasksRequest,
    AddTasksResponse,
    APIRequest,
    APIResponse,
    EditTaskRequest,
    EditTaskResponse,
    GenerateTaskSuggestionsRequest,
    GenerateTaskSuggestionsResponse, GetPracticeInfoRequest, GetPracticeInfoResponse,
    MarkTaskDeletedRequest, PracticeSubscription,
    Task,
    ToggleTasksCompleteRequest,
    ToggleTasksCompleteResponse, ToggleTasksDesiredEndState
} from "./model/RequestTypes";
import {googleAnalytics} from "./model/firebaseConnection";
import {elaborateTasks} from "./model/Tasks";
import {logEvent} from "firebase/analytics";
import {callAPI} from "./model/API";
import {DashboardComponent} from "./components/dashboard/Dashboard";
import {Practice, User} from "./model/databaseSchema";


type PageState = {
    error: string | undefined,
    practiceName: string | undefined,
    tasks: Task[],
    taskSuggestions: Task[],
    dynamicTaskSuggestions?: Task[],
    userConfig: User | undefined,
    subscription: PracticeSubscription | undefined
    practice: Practice | undefined
}


export default function Page() {
    const [pageState, setPageState] = useState<PageState>({
        error: undefined,
        practiceName: undefined,
        tasks: [],
        taskSuggestions: [],
        userConfig: undefined,
        subscription: undefined,
        practice: undefined
    });

    const [loading, setLoading] = useState(true);

    const logEventInternal = (name: string, params?: any) => {
        logEvent(googleAnalytics, name, {practiceName: pageState.practiceName ?? 'unknown', ...params});
    }

    const callAPIOrError =
        async <RequestType extends APIRequest, ResponseType extends APIResponse>(data: RequestType) => {
            try {
                return await callAPI<RequestType, ResponseType>(data);
            } catch (e: any) {
                setPageState({
                    error: e.message,
                    practiceName: pageState.practiceName,
                    tasks: pageState.tasks,
                    taskSuggestions: pageState.taskSuggestions,
                    userConfig: pageState.userConfig,
                    subscription: pageState.subscription,
                    practice: pageState.practice
                });
                return null;
            }
        }


    const addTasks = async (tasks: Task[]) => {
        const response = await callAPIOrError<AddTasksRequest, AddTasksResponse>({type: 'addTasks', tasks});
        if (response) {
            setPageState({
                error: undefined,
                practiceName: pageState.practiceName,
                tasks: response.newTasks,
                taskSuggestions: response.newTaskSuggestions,
                userConfig: pageState.userConfig,
                subscription: pageState.subscription,
                practice: pageState.practice
            });
        }

    }

    const editTask = async (task: Task) => {
        const response = await callAPIOrError<EditTaskRequest, EditTaskResponse>({type: 'editTask', task});
        if (response) {
            setPageState({
                error: undefined,
                practiceName: pageState.practiceName,
                tasks: response.newTasks,
                taskSuggestions: response.newTaskSuggestions,
                userConfig: pageState.userConfig,
                subscription: pageState.subscription,
                practice: pageState.practice
            });
        }
    }


    const handleMarkTasksComplete = async (tasks: Task[], desiredEndState: ToggleTasksDesiredEndState) => {
        const response = await callAPIOrError<ToggleTasksCompleteRequest, ToggleTasksCompleteResponse>({
            type: 'toggleTasksComplete',
            tasks,
            desiredEndState
        });
        if (response) {
            setPageState({
                error: undefined,
                practiceName: pageState.practiceName,
                tasks: response.newTasks,
                taskSuggestions: response.newTaskSuggestions,
                userConfig: pageState.userConfig,
                subscription: pageState.subscription,
                practice: pageState.practice
            });
        }
    }

    const handleMarkTaskDeleted = async (task: Task) => {
        const response = await callAPIOrError<MarkTaskDeletedRequest, ToggleTasksCompleteResponse>({
            type: 'markTaskDeleted',
            task
        });
        if (response) {
            setPageState({
                error: undefined,
                practiceName: pageState.practiceName,
                tasks: response.newTasks,
                taskSuggestions: response.newTaskSuggestions,
                userConfig: pageState.userConfig,
                subscription: pageState.subscription,
                practice: pageState.practice
            });
        }
    }

    const generateTaskSuggestions = async (prompt: string | null, base64String: {
        data: string,
        mimeType: string
    } | null) => {

        const response = await callAPIOrError<GenerateTaskSuggestionsRequest, GenerateTaskSuggestionsResponse>({
            type: 'generateTaskSuggestions',
            prompt: prompt ?? undefined,
            fileBase64: base64String ?? undefined
        });
        if (response) {
            setPageState({
                error: undefined,
                practiceName: pageState.practiceName,
                tasks: pageState.tasks,
                taskSuggestions: response.newTaskSuggestions,
                dynamicTaskSuggestions: response.generatedSuggestions,
                userConfig: pageState.userConfig,
                subscription: pageState.subscription,
                practice: pageState.practice
            });
        }
    }

    useEffect(() => {
        const f = async () => {
            const response = await callAPIOrError<GetPracticeInfoRequest, GetPracticeInfoResponse>({type: 'getPracticeInfo'});
            if (response) {
                setPageState({
                    error: undefined,
                    practiceName: response.practiceName,
                    tasks: response.tasks,
                    taskSuggestions: response.taskSuggestions,
                    userConfig: response.userConfig,
                    subscription: response.subscription,
                    practice: response.practice
                });
            }
            setLoading(false);
        }
        f();
    }, []);

    const params = new URLSearchParams(window.location.search);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const debugDate = params.get('debugDate');
    if (debugDate) {
        today.setTime(Date.parse(debugDate));
    }

    const tasks = elaborateTasks(pageState.tasks, today).sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime());

    const userSet = new Set<string>();
    if(pageState.practice && pageState.practice.users) {
        for(const user of Object.keys(pageState.practice.users)) {
            userSet.add(user);
        }
    }

    for(const task of tasks) {
        if(task.task.owner && !userSet.has(task.task.owner)) {
            userSet.add(task.task.owner);
        }
    }

    if(pageState.userConfig?.email) {
        userSet.add(pageState.userConfig.email);
    }

    const users = Array.from(userSet);

    return <DashboardComponent tasks={tasks} taskSuggestions={pageState.taskSuggestions}
                               error={pageState.error} practiceName={pageState.practiceName} addTasks={addTasks}
                               today={today}
                               changeTasksCompletionState={handleMarkTasksComplete}
                               editTask={editTask}
                               deleteTask={handleMarkTaskDeleted}
                               logEventInternal={logEventInternal}
                               generateTaskSuggestions={generateTaskSuggestions}
                               dynamicTaskSuggestions={pageState.dynamicTaskSuggestions}
                               loading={loading}
                               isFoundingCustomer={pageState.subscription?.type === 'active' && pageState.subscription.foundingCustomer}
                               trialDetails={pageState.subscription?.type === 'trial' ? {
                                   endDate: new Date(pageState.subscription.current_period_end),
                               } : undefined}
                               users={users}
    />


}