import {DBRiskAssessment} from "../model/databaseSchema";
import {useState} from "react";
import {RiskAssessmentComponent} from "../components/compliance/RiskAssessmentComponent";
import {httpsCallable} from "firebase/functions";
import {functions} from "../model/firebaseConnection";
import {
    DemoAPIResponse,
    DemoGenerateRiskAssessmentRequest,
} from "../model/RequestTypes";
import {Alert} from "react-bootstrap";


export const DemoRiskAssessmentPage = () => {

    const [riskAssessment, setRiskAssessment] = useState<DBRiskAssessment>({
        name: 'MPox Transmission Risk',
        createdAt: Date.now(),
        riskSummary: '',
        hazards: {}
    });
    const [error, setError] = useState<string | undefined>(undefined);

    const regenerateRiskAssessment = async (input: string) => {
        setError(undefined);
        try {
            const apiFunc = httpsCallable<DemoGenerateRiskAssessmentRequest, DemoAPIResponse>(functions, 'demoAPI');
            const response = await apiFunc({
                type: 'generateRiskAssessment',
                input: input,
                riskAssessmentName: riskAssessment.name
            });
            if (response.data.type === 'error') {
                setError(response.data.error);
            }
            if (response.data.type !== "generateRiskAssessment") {
                throw new Error('Unexpected response from server');
            }
            setRiskAssessment(response.data.riskAssessment);
        } catch (e: any) {
            setError(e.message);
        }
    }

    return <div className={'w-100'}>
        {error &&
            <Alert variant={'danger'}>ERROR: {error}</Alert>
        }
        <RiskAssessmentComponent riskAssessment={riskAssessment} regenerateRiskAssessment={regenerateRiskAssessment}
                                 editRiskAssessment={async (riskAssessment) => setRiskAssessment(riskAssessment)}/>
    </div>


}