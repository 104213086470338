import {Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import './page.css';
import {auth} from "../model/firebaseConnection";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LoadableValue} from "../model/LoadableValue";
import {
    AddPracticeUserRequest, AddPracticeUserResponse, DeletePracticeUserRequest, DeletePracticeUserResponse,
    GetPracticeUsersRequest, GetPracticeUsersResponse,
    GetReferralInfoRequest,
    GetReferralInfoResponse,
    GetRegularEmailSubscriptionsRequest,
    GetRegularEmailSubscriptionsResponse,
    GetUserInfoRequest,
    GetUserInfoResponse,
    PracticeSubscription,
    ReferAFriendRequest,
    ReferAFriendResponse,
    RegularEmailSubscriptions, SetSubscriptionForRegularEmailRequest, SetSubscriptionForRegularEmailResponse
} from "../model/RequestTypes";
import {callAPI} from "../model/API";
import {AvailableReferral, PracticeReferral} from "../../functions/src/databaseSchema";
import {Referrals} from "./Referrals";
import {PracticeUser} from "../model/databaseSchema";
import {PracticeUsersEditor} from "./PracticeUsersEditor";


type EmailPreferencesProps = {
    sendRegularEmailNow: (emailId: string) => void
    updateRegularEmailPreferences: (emailId: string, subscription: boolean) => void
    regularEmailPreferences: LoadableValue<RegularEmailSubscriptions>
}

const EmailPreferences = ({
                              sendRegularEmailNow,
                              regularEmailPreferences,
                              updateRegularEmailPreferences
                          }: EmailPreferencesProps) => {


    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Email</th>
                <th>Send Immediately</th>
                <th>Subscribe</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={'email-setting-name'}>Operations Agenda</td>
                <td className={'email-setting-sendNow'}><Button onClick={() => sendRegularEmailNow('opsAgenda')}>Send
                    Now</Button></td>
                <td className={'align-content-center fst-italic'}>Feature coming soon</td>
            </tr>
            <tr>
                <td className={'email-setting-name'}>Operations Summary</td>
                <td className={'email-setting-sendNow'}><Button onClick={() => sendRegularEmailNow('opsSummary')}>Send
                    Now</Button></td>
                <td className={'align-content-center fst-italic'}>Feature coming soon</td>
            </tr>
            <tr>
                <td className={'email-setting-name'}>New Feature Updates</td>
                <td className={'email-setting-sendNow'}></td>
                <td className={'align-content-center email-settings-subscribe'}><Form.Check
                    checked={regularEmailPreferences.type === 'loaded' && regularEmailPreferences.newFeatures}
                    onChange={(e) => updateRegularEmailPreferences('newFeatures', e.currentTarget.checked)}></Form.Check>
                </td>
            </tr>
            </tbody>
        </Table>
    );

}


type SettingsProps = {
    email: string
    logout: () => void
    practiceSubscription: LoadableValue<{ sub: PracticeSubscription }>
    sendRegularEmailNow: (emailId: string) => void,
    referrals: LoadableValue<{
        availableReferrals: AvailableReferral[]
        referralsMade: PracticeReferral[]
    }>
    referAFriend: (email: string, referralType: string) => void
    updateRegularEmailPreferences: (emailId: string, subscription: boolean) => void
    regularEmailPreferences: LoadableValue<RegularEmailSubscriptions>
    practiceUsers: LoadableValue<{ users: PracticeUser[] }>
    addUser: (email: string) => void
    deleteUser: (user: PracticeUser) => void
}

const SettingsPageComponent = ({
                                   email,
                                   logout, practiceSubscription, sendRegularEmailNow, referrals,
                                   referAFriend,
                                   regularEmailPreferences,
                                   updateRegularEmailPreferences,
                                   practiceUsers,
                                   addUser,
                                   deleteUser
                               }: SettingsProps) => {

    return (
        <Container className={'mt-5'}>
            <Row>
                <Col>
                    <h1 className={'ds-screen-title'}>Settings</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={1} xxl={2}/>
                <Col>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>General Account Settings </h2>
                        <p>{email}</p>
                        <Button onClick={logout}>Logout</Button>
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Practice Users</h2>
                        {practiceUsers.type === 'loaded' &&
                            <PracticeUsersEditor users={practiceUsers.users} deleteUser={deleteUser}
                                                 addUser={addUser}
                                                 currentUserEmail={email}/>}
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Email Preferences</h2>
                        <EmailPreferences sendRegularEmailNow={sendRegularEmailNow}
                                          regularEmailPreferences={regularEmailPreferences}
                                          updateRegularEmailPreferences={updateRegularEmailPreferences}/>
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Referrals</h2>
                        <Referrals referrals={referrals} referAFriend={referAFriend}/>
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Tasks</h2>
                        <Link to={"/onboarding"}><Button>Rerun onboarding</Button></Link>
                    </div>
                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Subscription Details</h2>
                        {practiceSubscription.type === "loading" && <div>Loading...</div>}
                        {practiceSubscription.type === "loaded" && <div>
                            {practiceSubscription.sub.type === "free" && <div>
                                <p>Free</p>
                                <p>{practiceSubscription.sub.reason}</p>
                            </div>}
                            {practiceSubscription.sub.type === "trial" && <div>
                                <p>Trial</p>
                                <p>Ends
                                    on {new Date(practiceSubscription.sub.current_period_end).toLocaleDateString('en-GB')}</p>
                            </div>}
                            {practiceSubscription.sub.type === "inactive" && <div>
                                <p>Inactive</p>
                                <p>{practiceSubscription.sub.last_period_end && `Ended on ${new Date(practiceSubscription.sub.last_period_end).toLocaleDateString('en-GB')}`}</p>
                            </div>}
                            {practiceSubscription.sub.type === "active" && <div>
                                <p>Active</p>
                                <p>Renews
                                    on {new Date(practiceSubscription.sub.current_period_end).toLocaleDateString('en-GB')}</p>
                                {practiceSubscription.sub.foundingCustomer && <p>Founding Customer</p>}
                            </div>}
                        </div>}
                    </div>

                    <div className={'ds-panel settings-panel'}>
                        <h2 className={'ds-panel-header'}>Cookies</h2>
                        <Button className="termly-display-preferences"> Consent Preferences</Button>
                    </div>

                </Col>
                <Col xs={12} xl={1} xxl={2}/>
            </Row>
        </Container>
    );
}


export function SettingsPage() {

    const [practiceSubscription, setPracticeSubscription] = useState<LoadableValue<{
        sub: PracticeSubscription
    }>>({type: "loading"});
    const [referrals, setReferrals] = useState<LoadableValue<{
        availableReferrals: AvailableReferral[]
        referralsMade: PracticeReferral[]
    }>>({type: "loading"});

    const [regulareEmailPreferences, setRegularEmailPreferences] = useState<LoadableValue<RegularEmailSubscriptions>>({type: "loading"});

    const [practiceUsers, setPracticeUsers] = useState<LoadableValue<{ users: PracticeUser[] }>>({type: "loading"});

    const navigate = useNavigate();


    const logout = async () => {
        await auth.signOut();
        navigate("/");
    }

    useEffect(() => {
        const f = async () => {
            try {
                const response = await callAPI<GetUserInfoRequest, GetUserInfoResponse>({type: "getUserInfo"});
                setPracticeSubscription({sub: response.practiceSubscription, type: "loaded"});

            } catch (e) {
                console.error(e);
            }
            try {
                const referralInfo = await callAPI<GetReferralInfoRequest, GetReferralInfoResponse>({type: "getReferralInfo"});
                setReferrals({
                    availableReferrals: referralInfo.availableReferrals,
                    referralsMade: referralInfo.referralsMade,
                    type: "loaded"
                });

            } catch (e) {
                console.error(e);
            }
            try {

                const getEmailPreferences = await callAPI<GetRegularEmailSubscriptionsRequest, GetRegularEmailSubscriptionsResponse>({type: "getRegularEmailSubscriptions"});
                setRegularEmailPreferences({type: "loaded", ...getEmailPreferences.subscriptions});

            } catch (e) {
                console.error(e);
            }
            try {

                const getPracticeUsers = await callAPI<GetPracticeUsersRequest, GetPracticeUsersResponse>({type: "getPracticeUsers"});
                setPracticeUsers({type: "loaded", users: getPracticeUsers.users});
            } catch (e) {
                console.error(e);
            }

        }
        f();
    }, []);


    const sendRegularEmailNow = async (emailId: string) => {
        try {
            await callAPI({type: "sendRegularEmailNow", emailId});
        } catch (e) {
            console.error(e);
        }
    }

    const referAFriend = async (email: string, referralType: string) => {
        try {
            const response = await callAPI<ReferAFriendRequest, ReferAFriendResponse>({
                type: "referAFriend",
                email,
                referralType
            });
            setReferrals({
                availableReferrals: response.availableReferrals,
                referralsMade: response.referralsMade,
                type: "loaded"
            });
        } catch (e) {
            console.error(e);
        }
    }

    const updateRegularEmailPreferences = async (emailId: string, subscription: boolean) => {
        try {
            const response = await callAPI<SetSubscriptionForRegularEmailRequest, SetSubscriptionForRegularEmailResponse>({
                type: "setSubscriptionForRegularEmail",
                emailId,
                subscription
            });
            setRegularEmailPreferences({type: "loaded", ...response.subscriptions});
        } catch (e) {
            console.error(e);
        }
    }

    const addUser = async (email: string) => {
        try {
            const response = await callAPI<AddPracticeUserRequest, AddPracticeUserResponse>({
                type: "addPracticeUser",
                email
            });
            setPracticeUsers({type: "loaded", users: response.users});
        } catch (e) {
            console.error(e);
        }
    }

    const deleteUser = async (user: PracticeUser) => {
        try {
            const response = await callAPI<DeletePracticeUserRequest, DeletePracticeUserResponse>({
                type: "deletePracticeUser",
                email: user.email
            });
            console.log(response);
            setPracticeUsers({type: "loaded", users: response.users});
        } catch (e) {
            console.error(e);
        }
    }


    return <SettingsPageComponent email={auth.currentUser?.email!} logout={logout}
                                  practiceSubscription={practiceSubscription}
                                  sendRegularEmailNow={sendRegularEmailNow}
                                  referrals={referrals}
                                  referAFriend={referAFriend}
                                  regularEmailPreferences={regulareEmailPreferences}
                                  updateRegularEmailPreferences={updateRegularEmailPreferences}
                                  addUser={addUser}
                                  deleteUser={deleteUser}
                                  practiceUsers={practiceUsers}

    />
}