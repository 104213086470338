import {DBRiskAssessment} from "../model/databaseSchema";
import {LoadableValue} from "../model/LoadableValue";
import {useEffect, useState} from "react";
import {callAPI} from "../model/API";
import {
    EditRiskAssessmentRequest, EditRiskAssessmentResponse,
    GetRiskAssessmentsRequest, GetRiskAssessmentsResponse,
    RegenerateRiskAssessmentRequest,
    RegenerateRiskAssessmentResponse
} from "../model/RequestTypes";
import {useParams} from "react-router-dom";
import {RiskAssessmentComponent} from "../components/compliance/RiskAssessmentComponent";




type RiskAssessmentPageState = {
    riskAssessment: DBRiskAssessment
}


export const RiskAssessment = () => {

    const [state, setState] = useState<LoadableValue<RiskAssessmentPageState>>({type: "loading"});

    const riskAssessmentName = useParams<{ riskAssessmentName: string }>().riskAssessmentName;


    useEffect(() => {
        const fetchRiskAssessment = async () => {
            const response = await callAPI<GetRiskAssessmentsRequest, GetRiskAssessmentsResponse>({type: 'getRiskAssessments'});
            const riskAssessment = response.riskAssessments.find((riskAssessment: DBRiskAssessment) => riskAssessment.name === riskAssessmentName);
            if (!riskAssessment) {
                setState({type: "error", error: new Error("Risk Assessment not found")});
                return;
            }
            setState({type: "loaded", riskAssessment: riskAssessment});
        }
        fetchRiskAssessment();
    }, []);


    if (state.type === "loading") {
        return <div>Loading...</div>
    }

    if (state.type === "error") {
        return <div>Error: {state.error.message}</div>
    }

    const regenerateRiskAssessment = async (input: string) => {
        const reponse = await callAPI<RegenerateRiskAssessmentRequest, RegenerateRiskAssessmentResponse>({
            type: 'regenerateRiskAssessment',
            input: input,
            riskAssessmentName: riskAssessmentName!
        });

        setState({type: "loaded", riskAssessment: reponse.riskAssessment});
    }

    const editRiskAssessment = async (riskAssessment: DBRiskAssessment) => {
        const response = await callAPI<EditRiskAssessmentRequest, EditRiskAssessmentResponse>({type: 'editRiskAssessment', riskAssessment});
        setState({type: "loaded", riskAssessment: response.editedRiskAssessment});
    }


    return <RiskAssessmentComponent riskAssessment={state.riskAssessment}
                                    regenerateRiskAssessment={regenerateRiskAssessment}
                                    editRiskAssessment={editRiskAssessment}
                                />

}