import "./layout.css";
import {useEffect, useState} from "react";
import {LoginState, useLoginState} from "./model/Authentication";
import {auth, googleAnalytics} from "./model/firebaseConnection";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendSignInLinkToEmail,
    sendPasswordResetEmail
} from "firebase/auth";
import {logEvent} from "firebase/analytics";
import {Outlet, useLocation} from "react-router-dom";
import {MyPMNavBar} from "./components/layout/MyPMNavBar";
import {LoginRequiredWrapper} from "./components/layout/LoginRequiredWrapper";
import {callAPI} from "./model/API";
import {
    BuyPackageNowRequest,
    BuyPackageNowResponse,
    GetUserInfoRequest,
    GetUserInfoResponse, SendMagicLinkRequest, SendMagicLinkResponse,
    SetupUserPracticeRequest,
    SetupUserPracticeResponse
} from "./model/RequestTypes";
import {PaymentIsRequiredWrapper} from "./components/layout/PaymentIsRequiredWrapper";
import {PackageOffer, PracticeNameIdLookup} from "../functions/src/databaseSchema";
import {PracticeSetupIsRequiredWrapper} from "./components/layout/PracticeSetupIsRequiredWrapper";
import {UserState} from "./components/layout/LayoutState";


type LayoutCompProps = {
    loginState: LoginState,
    registerNewUser: (email: string, password: string) => Promise<void>
    loginUser: (email: string, password: string) => Promise<void>
    authError?: string
    verificationRequested: () => void
    pathname: string
    buyNow: (offer: PackageOffer) => void
    userState: UserState
    practiceNameIdLookup: PracticeNameIdLookup
    setupUserPractice: (practiceId: string) => void
    loginWithMagicLink: (email: string) => Promise<void>
    resetPassword: (email: string) => Promise<void>
}
const LayoutComp = ({
                        loginState,
                        loginUser,
                        registerNewUser,
                        authError,
                        verificationRequested,
                        pathname,
                        userState,
                        practiceNameIdLookup,
                        buyNow,
                        setupUserPractice,
                        loginWithMagicLink,
                        resetPassword
                    }: LayoutCompProps) => {

    if (loginState.state === "initialising") {
        return <div>Loading...</div>
    }

    return (
        <div className="ds-screen">
            <MyPMNavBar pathname={pathname} userState={userState}/>
            <div className={'ds-screen-content'}>
                <LoginRequiredWrapper pathname={pathname}
                                      loginState={loginState}
                                      verificationRequested={verificationRequested}
                                      registerNewUser={registerNewUser}
                                      loginUser={loginUser}
                                      authError={authError}
                                      loginWithMagicLink={loginWithMagicLink}
                                      resetPassword={resetPassword}
                >
                    <PracticeSetupIsRequiredWrapper pathName={pathname}
                                                    userState={userState}
                                                    practiceNameIdLookup={practiceNameIdLookup}
                                                    setupUserPractice={setupUserPractice}
                    >
                        <PaymentIsRequiredWrapper userState={userState}
                                                  pathname={pathname} buyNow={buyNow}>
                            <Outlet/>
                        </PaymentIsRequiredWrapper>
                    </PracticeSetupIsRequiredWrapper>
                </LoginRequiredWrapper>
            </div>
        </div>
    )
}


type LayoutState = {
    userState: UserState
    practiceNameIdLookup: PracticeNameIdLookup | undefined
    error: string | undefined
}


export default function Layout() {

    const loginState = useLoginState();
    const path = useLocation();

    const [layoutState, setLayoutState] = useState<LayoutState>({
        practiceNameIdLookup: undefined,
        userState: {
            type: "loading"
        },
        error: undefined
    });

    const registerNewUser = async (email: string, password: string) => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            logEvent(googleAnalytics, 'sign_up', {
                method: 'email'
            })
            setLayoutState(
                {
                    ...layoutState,
                    error: undefined
                }
            )
        } catch (e: any) {
            let authError: string | undefined = undefined;
            if (e.code === "auth/wrong-password") {
                authError = "Incorrect password, please check your password and try again";
            } else if (e.message && e.message.includes("NHS EMAIL")) {
                authError = "Please sign up with an NHS email address";
            } else if (e.message && e.message.includes("UNVERIFIED")) {
                authError = "We're rolling out slowly, your email hasn't been setup yet.  If you think you're getting this message in error, please contact support at contact@mypracticemanager.co.uk";
            } else {
                authError = "An unknown error occurred, please try again, if this persists please contact support at contact@mypracticemanager.co.uk";
            }
            setLayoutState(
                {
                    ...layoutState,
                    error: authError
                }
            );
        }
    }

    const loginUser = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            logEvent(googleAnalytics, 'login', {
                method: 'email'
            })
            setLayoutState(
                {
                    ...layoutState,
                    error: undefined
                }
            )
        } catch (e: any) {
            let authError: string | undefined = undefined;
            if (e.code === "auth/wrong-password") {
                authError = "Incorrect password, please check your password and try again";
            } else {
                authError = "Username or password incorrect, please check your details and try again";
            }
            setLayoutState(
                {
                    ...layoutState,
                    error: authError
                }
            );
        }
    }

    const loginWithMagicLink = async (email: string) => {
        try {
            await callAPI<SendMagicLinkRequest, SendMagicLinkResponse>({
                type: 'sendMagicLink',
                email,
                settings: {
                    url: window.location.origin + "/_auth"
                }
            })
            if (localStorage) {
                localStorage.setItem("emailForSignIn", email);
            }
            logEvent(googleAnalytics, 'login', {
                method: 'magic-link'
            })
            setLayoutState(
                {
                    ...layoutState,
                    error: undefined
                }
            )
        } catch (e: any) {
            console.log(e);
            let authError: string | undefined = undefined;
            if (e.code === "auth/wrong-password") {
                authError = "Incorrect password, please check your password and try again";
            } else {
                authError = "Username or password incorrect, please check your details and try again";
            }
            setLayoutState(
                {
                    ...layoutState,
                    error: authError
                }
            );
        }
    }

    const resetPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email, {
                url: window.location.origin,
                handleCodeInApp: true
            });
            setLayoutState(
                {
                    ...layoutState,
                    error: undefined
                }
            )
        } catch (e: any) {
            console.error(e);
            setLayoutState(
                {
                    ...layoutState,
                    error: e.message
                }
            );
        }
    }

    const buyNow = async (offer: PackageOffer) => {
        try {
            const response = await callAPI<BuyPackageNowRequest, BuyPackageNowResponse>({
                type: 'buyPackageNow',
                packageOffer: offer
            });
            logEvent(googleAnalytics, "ecommerce_purchase",
                {
                    value: offer.price,
                    currency: 'GBP',
                    items: [
                        {
                            item_id: offer.id,
                            item_name: offer.name,
                            quantity: 1,
                            price: offer.price
                        }
                    ]
                });
            console.log("Bought package", response);
            setLayoutState({
                userState: {
                    type: "loaded",
                    userConfig: response.userConfig,
                    packageOffers: response.packageOffers,
                    practiceMetadata: response.practiceMetaData,
                    subscription: response.practiceSubscription
                },
                practiceNameIdLookup: layoutState.practiceNameIdLookup,
                error: undefined
            });
        } catch (e: any) {
            setLayoutState({
                    ...layoutState,
                    error: e.message
                }
            )
            console.error(e);
        }
    }

    const verificationRequested = async () => {
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true
        }
        await sendEmailVerification(auth.currentUser!, actionCodeSettings);
    }

    useEffect(() => {
        const f = async () => {
            if (loginState.state === "logged-in") {
                try {
                    const userInfo = await callAPI<GetUserInfoRequest, GetUserInfoResponse>({type: 'getUserInfo'});
                    setLayoutState({
                        userState: {
                            type: "loaded",
                            userConfig: userInfo.userConfig,
                            packageOffers: userInfo.packageOffers,
                            practiceMetadata: userInfo.practiceMetaData,
                            subscription: userInfo.practiceSubscription
                        },
                        practiceNameIdLookup: userInfo.practiceNameIdLookup,
                        error: undefined,
                    });
                } catch (e: any) {
                    console.error(e);
                    setLayoutState({
                        ...layoutState,
                        error: e.message
                    });
                }
            }
        }
        f();
    }, [loginState]);

    const setupUserPractice = async (practiceId: string) => {
        try {
            const response = await callAPI<SetupUserPracticeRequest, SetupUserPracticeResponse>({
                type: 'setupUserPractice',
                practiceCode: practiceId
            });
            setLayoutState({
                userState: {
                    type: "loaded",

                    userConfig: response.userConfig,
                    packageOffers: response.packageOffers,
                    practiceMetadata: response.practiceMetaData,
                    subscription: response.practiceSubscription
                },
                practiceNameIdLookup: layoutState.practiceNameIdLookup,
                error: undefined,
            })
        } catch (e: any) {
            setLayoutState({
                ...layoutState,
                error: e.message
            });
        }
    }


    const practiceNameIdLookupOrEmpty: PracticeNameIdLookup = layoutState.practiceNameIdLookup ?? {
        practices: []
    };

    return <LayoutComp loginState={loginState}
                       registerNewUser={registerNewUser}
                       loginUser={loginUser}
                       authError={layoutState.error}
                       verificationRequested={verificationRequested}
                       pathname={path.pathname}
                       userState={layoutState.userState}
                       practiceNameIdLookup={practiceNameIdLookupOrEmpty}
                       buyNow={buyNow}
                       setupUserPractice={setupUserPractice}
                       loginWithMagicLink={loginWithMagicLink}
                       resetPassword={resetPassword}
    />
}