import React, {useEffect, useState} from "react";
import {Button, Col, Container, Nav, Row, Tab} from "react-bootstrap";
import {OnboardingSubject} from "../../functions/src/RequestTypes";
import {OnboardingSubjectComponent} from "./OnboardingSubjectComponent";
import {TaskTemplate} from "../model/databaseSchema";
import {
    AddTasksRequest,
    AddTasksResponse,
    GetOnboardingInfoRequest,
    GetOnboardingInfoResponse,
    Task,
    ToggleTasksCompleteRequest,
    ToggleTasksCompleteResponse
} from "../model/RequestTypes";
import {useLocation, useNavigate} from "react-router-dom";
import "./page.css";
import {elaborateTasks} from "../model/Tasks";
import {callAPI} from "../model/API";


type OnboardingPageProps = {
    error: string | null,
    onboardingTemplate: Record<string, OnboardingSubject> | undefined,
    addTasks: (tasks: TaskTemplate[]) => Promise<void>
    goToHome: () => void
    loading: boolean,
}

const OnboardingPageComponent =
    ({
         onboardingTemplate,
         addTasks,
         goToHome,
     }: OnboardingPageProps) => {

        const [activeTab, setActiveTab] = useState<string>('introduction');

        const [completedSteps, setCompletedSteps] = useState<Record<string, string>>({});

        if(!onboardingTemplate){
            return <div>Loading...</div>
        }

        const subjectKeys = Object.keys(onboardingTemplate).sort();

        return (
            <Container fluid className={'mt-4'}>

                <Tab.Container activeKey={activeTab}
                               onSelect={(k) => setActiveTab(k ?? 'introduction')}>
                    <Row>
                        <Col xl={3}>
                            <div className={'onboarding-nav-panel-wrapper'}>
                                <div className={'ds-panel'}>
                                    <h2 className={'ds-panel-header'}>Onboarding Steps</h2>

                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="introduction">
                                                <div
                                                    className={'onboarding-nav-link'}>Introduction {completedSteps['introduction'] &&
                                                    <i className="bi bi-check-circle-fill onboarding-step-check"></i>}</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        {subjectKeys.map((key) => {
                                            return <Nav.Item key={key}>
                                                <Nav.Link eventKey={key}>
                                                    <div
                                                        className={'onboarding-nav-link'}>{onboardingTemplate[key].name}
                                                        {completedSteps[key] &&
                                                            <i className="bi bi-check-circle-fill onboarding-step-check"></i>}
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        })}
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} xl={9} xxl={6}>
                            <Tab.Content>
                                <Tab.Pane eventKey="introduction">
                                    <div className={'ds-panel'}>
                                        <h2 className={'ds-panel-header'}>Introduction</h2>
                                        <p>Welcome to My Practice manager. During this onboarding
                                            process we'll guide you
                                            through tasks in various areas. We've split the tasks into
                                            ones where we need
                                            some input, and tasks that are standard across practices.
                                            For example, payroll
                                            is different at different practices, so you'll need to tell us your pay roll
                                            dates</p>
                                        <p>Once you're setup you can make further changes in the app</p>
                                        <Button onClick={() => {
                                            setActiveTab(subjectKeys[0])
                                            setCompletedSteps({
                                                ...completedSteps,
                                                'introduction': 'completed'
                                            })
                                        }}>Continue</Button>
                                        <div className={'onboarding-introduction-start-blank'}>
                                            <h3>
                                                Start with a clean slate<i className="bi bi-calendar mx-3"></i>
                                            </h3>
                                            <p>If you'd like to start with a clean slate, you can skip the onboarding and add
                                                your own tasks manually</p>
                                            <p>If you want to onboard later, go to settings (top right of this screen) and use the button</p>
                                            <Button onClick={() => {
                                                goToHome();
                                            }} variant={'secondary'}>Start with a blank slate</Button>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                {subjectKeys.map((key) => {
                                    return <Tab.Pane key={key} eventKey={key}>
                                        <OnboardingSubjectComponent addTasks={addTasks}
                                                                    subject={onboardingTemplate[key]}
                                                                    next={() => {
                                                                        const currentIndex = subjectKeys.indexOf(key);
                                                                        setCompletedSteps({
                                                                            ...completedSteps,
                                                                            [key]: 'completed'
                                                                        });
                                                                        if (currentIndex < subjectKeys.length - 1) {
                                                                            setActiveTab(subjectKeys[currentIndex + 1]);
                                                                        } else {
                                                                            goToHome();
                                                                        }
                                                                    }}/>
                                    </Tab.Pane>
                                })}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        )
    }
type OnboardingPageState = {
    error: string | null,
    onboardingTemplate: Record<string, OnboardingSubject> | undefined,
}

export const OnboardingPage = () => {

    const navigate = useNavigate();
    const path = useLocation();

    const [onboardingState, setOnboardingState] = useState<OnboardingPageState>({
        error: null,
        onboardingTemplate: undefined,
    });

    const [loading, setLoading] = useState<boolean>(true);


    const fetchData = async () => {
        setLoading(true);
        try {
            const onboardingInfo = await callAPI<GetOnboardingInfoRequest, GetOnboardingInfoResponse>({
                type: 'getOnboardingInfo',
                onboardingTemplateId: path.pathname === '/dental' ? 'dental' : 'national'
            });

            setOnboardingState({
                error: null,
                onboardingTemplate: onboardingInfo.template.subjects,
            });
        } catch (e: any) {
            setOnboardingState({
                error: e.message,
                onboardingTemplate: undefined,
            });
        }
        setLoading(false);
    }


    useEffect(() => {
        fetchData();
    }, []);

    const addTasks = async (tasks: Task[]) => {
        await callAPI<AddTasksRequest, AddTasksResponse>({type: 'addTasks', tasks});
        await fetchData();
    }

    const toggleTasksComplete = async (tasks: Task[]) => {
        await callAPI<ToggleTasksCompleteRequest, ToggleTasksCompleteResponse>({
            type: 'toggleTasksComplete',
            tasks,
            desiredEndState: "complete"
        });
        await fetchData();
    }

    const addTaskTemplates = async (taskTemplates: TaskTemplate[]) => {
        const convertedTasks: Task[] = taskTemplates.map((task) => {
            if (task.type === 'oneOff') {
                return {
                    id: task.id,
                    name: task.name,
                    details: task.details,
                    dueDate: task.dueDate,
                    type: task.type,
                    tags: task.tags
                }
            } else {
                return {
                    id: task.id,
                    name: task.name,
                    details: task.details,
                    frequency: task.frequency,
                    type: task.type,
                    tags: task.tags
                }
            }
        });

        await addTasks(convertedTasks);
        const elaboratedTasks = elaborateTasks(convertedTasks, new Date());
        const lateTasks = elaboratedTasks.filter(task => task.dueDate.getTime() < new Date().getTime());
        if (lateTasks.length > 0) {
            await toggleTasksComplete(lateTasks.map(task => task.task));
        }
    }

    return <OnboardingPageComponent error={onboardingState.error}
                                    onboardingTemplate={onboardingState.onboardingTemplate}
                                    addTasks={addTaskTemplates}
                                    goToHome={() => {
                                        navigate('/');
                                    }}
                                    loading={loading}
    />

}