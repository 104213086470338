import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import {PackageOffer} from "../../model/databaseSchema";

export type InvoicedManuallyPurchaseOptionsProps = {
    packageOffers: PackageOffer[]
    buyNow: (offer: PackageOffer) => void,
    useStripe: boolean
}

export const InvoicedManuallyPurchaseOptions = ({
                                                    packageOffers,
                                                    buyNow,
                                                    useStripe
                                                }: InvoicedManuallyPurchaseOptionsProps) => {


    return <Container className={'mt-4'}>
        <Row>
            <Col xs={0} md={0} lg={0} xl={1}/>
            <Col xs={12} md={12} lg={12} xl={10}>
                <div className={'ds-panel'}>
                    <h2 className={'ds-panel-header'}>Payment</h2>
                    {packageOffers.length === 0 ?
                        <div className={"onboarding-packages-not-available"}>
                            <p>There are currently no packages available for your practice</p>
                            <p>Please contact support contact@mypracticemanager.co.uk</p>
                        </div>
                        :
                        <Container fluid className={'mt-3'}>
                            <Row>
                                {packageOffers.map((offer) => {
                                    return <Col xs={12} lg={6} key={offer.name}
                                                className={'d-flex flex-row g-5'}>
                                        <div
                                            className={'onboarding-package-card ' + offer.id}>

                                            <div
                                                className={'onboarding-package-card-header'}>
                                                <h3 className={'onboarding-package-name'}>{offer.name}</h3>
                                                <div className={'onboarding-package-price-wrapper'}>
                                                            <span
                                                                className={'onboarding-package-price'}> <span
                                                                className={'onboarding-package-price-pound'}>£</span>{offer.price.toLocaleString('en-GB', {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2
                                                            })}
                                                            </span>
                                                    {offer.price > 0 && <span>/year</span>}
                                                </div>
                                                {offer.listSize > 0 &&
                                                    <div
                                                        className={'onboarding-package-list-size'}>For your practice
                                                        of {offer.listSize.toLocaleString("en-GB", {maximumFractionDigits: 0})} patients
                                                    </div>
                                                }
                                            </div>
                                            <div className={'onboarding-package-card-body'}>
                                                <p className={'onboarding-package-features'}>
                                                    {offer.features.map((feature) => {
                                                        return <div key={feature}
                                                                    className={'onboarding-package-feature'}><i
                                                            className="bi bi-check-circle-fill onboarding-package-feature-icon"></i>{feature}
                                                        </div>
                                                    })}
                                                </p>
                                                <div className={'flex-grow-1'}>
                                                </div>
                                                {useStripe ?
                                                    <p className={'onboarding-package-disclaimer mt-5'}>By clicking
                                                        'Subscribe Now,' you agree to our <a
                                                            href="https://www.mypracticemanager.co.uk/terms-and-conditions"
                                                            target="_blank" rel="noopener noreferrer">Terms and
                                                            Conditions</a>
                                                    </p>
                                                    : <p className={'onboarding-package-disclaimer mt-5'}>By clicking
                                                        'Subscribe Now,' you agree to our <a
                                                            href="https://www.mypracticemanager.co.uk/terms-and-conditions"
                                                            target="_blank" rel="noopener noreferrer">Terms and
                                                            Conditions</a> and to receive an invoice from My
                                                        Practice Manager Ltd for
                                                        £{offer.price.toLocaleString('en-GB', {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}, payable in advance for 12 months of access to the My
                                                        Practice Manager platform.
                                                    </p>}

                                                <Button
                                                    variant={offer.id === 'founding' ? "primary" : "secondary"}
                                                    className={'onboarding-package-buy-now'}
                                                    onClick={() => buyNow(offer)}>Subscribe Now
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Container>
                    }
                </div>
            </Col>
            <Col xs={0} md={0} lg={0} xl={1}/>
        </Row>
    </Container>
}